<template>
  <div>
    <v-form autocomplete="new-password" ref="form" v-model="valid" :lazy-validation="lazy">
      <v-text-field
        v-model="formData.email"
        label="Email"
        name="login"
        prepend-icon="mdi-account"
        type="text"
        :rules="emailRules"
      />
    </v-form>
  </div>
</template>
<script>
import validations from "@/mixins/validations";
export default {
  mixins: [validations],
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      valid: true,
      lazy: false
    };
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate();
    }
  }
};
</script>
